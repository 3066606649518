import { useState } from "preact/hooks";
import CardModal from "./CardModal.tsx";

interface CardListProps {
  cards: Card[];
  type: "units" | "spells";
  database: string;
  onCardUpdate: (fileType: string, cardData: string) => void;
}

export default function CardList(
  { cards, type, database, onCardUpdate }: CardListProps,
) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const [isNewCard, setIsNewCard] = useState(false);
  const [activeTab, setActiveTab] = useState<"units" | "spells">("units");
  const [searchTerm, setSearchTerm] = useState("");
  const [costFilter, setCostFilter] = useState<number | "">("");

  const getEmptyCard = (): Card => {
    if (type === "spells") {
      return {
        Name: "",
        Type: "Spell",
        Cost: 1,
        Initiative: -1,
        Effects: [{
          Trigger: "Cast",
          Type: "ResourceGain",
          Description: "",
        }],
      };
    }
    return {
      Name: "",
      Attack: 1,
      Range: 1,
      Life: 1,
      MovementSpeed: 1,
      Initiative: 5,
      Cost: 1,
      Effects: [],
    };
  };
  const handleDelete = async (cardName: string) => {
    if (!confirm(`Are you sure you want to delete ${cardName}?`)) {
      return;
    }

    try {
      const response = await fetch("/api/deleteCard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          database,
          fileType: type,
          cardName,
        }),
      });

      if (!response.ok) throw new Error("Failed to delete card");
      const updatedCards = await response.json();
      onCardUpdate(type, JSON.stringify(updatedCards));
    } catch (err) {
      console.error("Failed to delete card:", err);
    }
  };

  const handleCreate = () => {
    setSelectedCard(getEmptyCard());
    setIsNewCard(true);
    setModalOpen(true);
  };

  const handleEdit = (card: Card) => {
    setSelectedCard(card);
    setIsNewCard(false);
    setModalOpen(true);
  };
  const getCardImageUrl = (card: Card) => {
    const encodedName = encodeURIComponent(card.Name);
    return `https://game-files.mehmke.click/assets/${
      card.Type === "Spell" ? "spells" : "units"
    }/${encodedName}/cardImage.jpeg`;
  };

  const handleSave = async (fileType: string, cardData: string) => {
    if (isNewCard) {
      try {
        const response = await fetch("/api/createCard", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            database,
            fileType,
            newCard: JSON.parse(cardData),
          }),
        });

        if (!response.ok) throw new Error("Failed to create card");
        const updatedCards = await response.json();
        onCardUpdate(fileType, JSON.stringify(updatedCards));
      } catch (err) {
        console.error("Failed to create card:", err);
      }
    } else {
      onCardUpdate(fileType, cardData);
    }
    setModalOpen(false);
    setIsNewCard(false);
  };
  const filteredCards = cards.filter((card) => {
    const matchesSearch = card.Name.toLowerCase().includes(
      searchTerm.toLowerCase(),
    );
    const matchesCost = costFilter === "" || card.Cost === costFilter;
    let matchesType = card.Type != "Spell" && activeTab == "units";
    if (activeTab != "units") {
      matchesType = card.Type == "Spell";
    }
    return matchesSearch && matchesCost && matchesType;
  });

  // Updated return section of CardList.tsx
  return (
    <div class="p-4">
      {/* Header section */}
      <div class="flex items-center justify-between mb-4">
        <h2 class="text-xl font-bold">Cards</h2>
      </div>

      {/* Tabs and filters */}
      <div class="mb-4">
        {/* Tabs */}
        <div class="flex border-b">
          <button
            class={`px-6 py-2 ${
              activeTab === "units"
                ? "border-b-2 border-gray-800 text-gray-800"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("units")}
          >
            Units
          </button>
          <button
            class={`px-6 py-2 ${
              activeTab === "spells"
                ? "border-b-2 border-purple-500 text-purple-600"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("spells")}
          >
            Spells
          </button>
        </div>

        {/* Search and filters row */}
        <div class="flex gap-2 mt-4">
          <input
            type="text"
            placeholder="Search by name"
            class="flex-grow px-3 py-1 border rounded text-sm"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
          />
          <select
            class="px-3 py-1 border rounded text-sm bg-gray-50 w-32"
            value={costFilter}
            onChange={(e) =>
              setCostFilter(
                e.currentTarget.value === ""
                  ? ""
                  : Number(e.currentTarget.value),
              )}
          >
            <option value="">All Costs</option>
            {Array.from(new Set(cards.map((card) => card.Cost))).sort((a, b) =>
              a - b
            ).map((cost) => <option key={cost} value={cost}>{cost}</option>)}
          </select>
          <button
            class="px-4 py-1 bg-green-500 text-white rounded text-sm hover:bg-green-600"
            onClick={handleCreate}
          >
            Create New {activeTab === "units" ? "Unit" : "Spell"}
          </button>
        </div>
      </div>

      {/* Cards Grid */}
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {filteredCards.map((card) => (
          <div
            key={card.Name}
            class="border rounded bg-white shadow-sm flex flex-col"
          >
            <div class="p-3 flex-grow">
              {/* Card Header with Image and Basic Info */}
              <div class="flex-col">
                {/* Image - Fixed size */}
                <div class="w-24 h-24 flex-shrink-0 mr-3">
                  <img
                    src={getCardImageUrl(card)}
                    alt={card.Name}
                    class="w-full h-full object-cover rounded"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = "/placeholder.png";
                      target.classList.add("opacity-50");
                    }}
                  />
                </div>

                {/* Content Container */}
                <div class="flex-grow min-w-0 overflow-hidden">
                  {/* Title and Cost */}
                  <div class="flex justify-between items-start mb-2">
                    <h3 class="font-medium truncate max-w-[70%]">
                      {card.Name}
                    </h3>
                    <span class="ml-2 px-2 py-0.5 bg-blue-100 text-blue-800 text-xs rounded flex-shrink-0">
                      Cost: {card.Cost}
                    </span>
                  </div>

                  {/* Stats Grid */}
                  {!card.Type && (
                    <div class="grid grid-cols-2 gap-x-4 gap-y-1 mt-1 text-sm text-gray-600">
                      <div class="truncate">Attack: {card.Attack}</div>
                      <div class="truncate">Range: {card.Range}</div>
                      <div class="truncate">Life: {card.Life}</div>
                      <div class="truncate">Speed: {card.MovementSpeed}</div>
                      <div class="truncate">Init: {card.Initiative}</div>
                    </div>
                  )}

                  {/* Effects Section */}
                  <div class="mt-2 text-sm">
                    <div class="font-medium text-gray-700 mb-1">Effects:</div>
                    <ul class="list-disc pl-4 text-sm text-gray-600 space-y-1 max-h-24 overflow-y-auto">
                      {card.Effects.map((effect, index) => (
                        <li key={index} class="truncate">
                          {effect.Trigger}: {effect.Description}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Actions - Fixed at bottom */}
            <div class="border-t px-3 py-2 flex justify-end gap-2 bg-gray-50 mt-auto">
              <button
                class="px-3 py-1 bg-blue-500 text-white rounded-sm text-sm hover:bg-blue-600"
                onClick={() => handleEdit(card)}
              >
                Edit
              </button>
              <button
                class="px-3 py-1 bg-red-500 text-white rounded-sm text-sm hover:bg-red-600"
                onClick={() => handleDelete(card.Name)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <CardModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setIsNewCard(false);
        }}
        onSave={handleSave}
        fileType={activeTab}
        initialCard={selectedCard
          ? JSON.stringify(selectedCard, null, 2)
          : undefined}
      />
    </div>
  );
}
